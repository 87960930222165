<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reporte')+': '+$t('Ventas')}}</h3>
		</div>
		<div class="p-col-12">
			<div class="card">
                <DataTable :value="poliza" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm"
                    :paginator="true" :rows="25"  >
                    <template #header>
                        <div class="table-header">
                            <div>
                                {{$t('Fecha')+'s'}}:
                                <InputMask v-model="fecha1" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                                <InputMask v-model="fecha2" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                            </div>
                            <Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar()"></Dropdown>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column :header="$t('Fecha')" field="fecha" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fpago)}}                     
                        </template>
                    </Column>   
                    <Column field="codigo" :header="$t('Número Póliza')" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.renovacion>0">
                                {{slotProps.data.codigo2+slotProps.data.renovacion}}    
                            </div>
                            <div v-else>
                                {{slotProps.data.codigo}}    
                            </div>                         
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Customer number')" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')+' #'" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                            
                        </template>
                    </Column>
                    <Column field="duracion_name" :header="$t('Duración')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            <div v-if="i18n.locale() == 'es'">{{slotProps.data.duracion_nombre}}</div>
                            <div v-else>{{slotProps.data.duracion_name}}</div>     
                        </template>
                    </Column>
                    <Column field="activacion" :header="$t('Activación')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.activacion}}
                        </template>
                    </Column>
                    <Column field="vence" :header="$t('Vencimiento')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.vence}}
                        </template>
                    </Column>
                    <Column :header="$t('Recibo')" field="factura" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.factura}}                              
                        </template>
                    </Column>   
                    <Column field="cliente" :header="$t('Usuario')+' #'" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.created.nombre}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Oficina')" field="created.origen" :sortable="true" headerStyle="width: 250px">
                        <template #body="slotProps">
                            {{slotProps.data.created.origen}}                              
                        </template>
                    </Column>   
                    <Column :header="$t('Prima Bruta WA')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.primaini*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>
                    <Column :header="$t('Recargo Años del Vehículo')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.recarga_veh*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>
                    <Column :header="$t('Recargo Edad Persona')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.recarga_edad*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>
                    <Column :header="$t('Recargo Volante')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.recarga_volante*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>
                    <Column :header="$t('Total WA')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.totalwa*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>
                    <Column :header="$t('Sin reclamación')" headerStyle="width: 180px">sss
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.totalwa*slotProps.data.evaluacion.noclaim*slotProps.data.evaluacion.duracion)}}
                       </template>
                    </Column>
                    <Column :header="$t('Cobertura Adicional')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.monto*0)}}
                        </template>
                    </Column>
                    <Column :header="$t('Forma de pago')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.efectivo>0">
                                Cash
                            </div>
                            <div v-else>
                                No Cash
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('Descuento Especial')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.totalwa*slotProps.data.evaluacion.duracion*slotProps.data.evaluacion.valor)}}
                        </template>
                    </Column>
                    <Column :header="$t('Cuotas')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.evaluacion.cuotas}}
                        </template>
                    </Column>
                    <Column :header="$t('Cargo Cuota')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.primabrutat*slotProps.data.evaluacion.porcentaje/100)}}
                        </template>
                    </Column>
                    <Column :header="$t('Prima WA')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.primabrutat*(1+slotProps.data.evaluacion.porcentaje/100)*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>
                    <Column :header="$t('Costo')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.kosten)}}
                        </template>
                    </Column>
                    <Column :header="$t('OB')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.ob)}}
                        </template>
                    </Column>
                    <Column :header="$t('Prima Total')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.monto)}}
                        </template>
                    </Column>
                    <Column :header="$t('Sabee')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            <div v-if="(slotProps.data.sabee)<0" style="color:red;">
                                {{moneda(slotProps.data.sabee)}}
                            </div>
                            <div v-else>
                                {{moneda(slotProps.data.sabee)}}
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('Broker')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.broker<0" style="color:red;">
                                {{moneda(slotProps.data.broker)}}
                            </div>
                            <div v-else>
                                {{moneda(slotProps.data.broker)}}
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('Cambio')" headerStyle="width: 80px">
                        <template #body="slotProps">
                            <i class="pi pi-fast-backward" v-if="slotProps.data.cambio"></i>
                        </template>
                    </Column>
                    <template #footer footerStyle="text-align:right">
						<div class="p-grid p-align-center vertical-container">
							<div class="p-col-11">
                                {{$t('Prima Total')}}: {{moneda(monto)}} <br> 
                                {{$t('Pagado')}}: {{moneda(pagos)}} <br> 
                                {{$t('Pago Efectivo')}}: {{moneda(efectivo)}} <br> 
                                {{$t('SABEE')}}: {{moneda(iss)}} <br> 
                                {{$t('BROKER')}}: {{moneda(broker)}} 
							</div>
							<div class="p-col-1">
                                <Button icon="pi pi-download" class="p-button-rounded p-button-warning p-mr-2" @click="VerXLS()" v-if="poliza.length>0"/>
							</div>
						</div>
                    </template>
                </DataTable>
			</div>
        </div>

    </div>
</template>

<script>
import API from "../service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";
import XLS from "../service/XLS";

export default {
	data() {
		return {
			selectedCustomers: null,
			filters: {},
			i18n: null,
            caso: null,
            poliza: [],
			url: null,
			opcion: null,
			opciones: [],
            polizas: [],
            police: {},
            duracion: [],
            cuotas: [],
            fecha1: null,
            fecha2: null,
            minimo: null,
            maximo: null,
            monto: 0,
            pagos: 0,
            deudas: 0,
            efectivo: 0,
            iss: 0,
            broker: 0,
        }
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
        const Consulta = new API('Reportes');
        this.fecha1 = Consulta.fstring(this.$store.state.fecha);
        this.fecha2 = Consulta.fstring(this.$store.state.fecha);
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
			this.$store.commit('Loading');
            const Consulta = new API('Reportes');
            Consulta.Ini('Report12').then(result => {
                //this.$store.state.error = result;
                if (result.consult.opciones){
                    this.opciones = result.consult.opciones;
                }
                this.fecha1 = Consulta.fstring(result.consult.fecha);
                this.$store.commit('Loading');
            }); 
        },
		VerXLS(){
            const xls = new XLS();
            xls.Reporte12(this.poliza);
		},
        Buscar(){
            if(this.opcion){
                this.$store.commit('Loading');
                this.poliza = [];
                this.polizas = [];
                this.monto = 0;
                this.pagos = 0;
                this.deudas = 0;
                this.efectivo = 0;
                this.iss = 0;
                this.broker = 0;
                const Consulta = new API('Reportes');
                Consulta.Procesar('Report12',{
                    caso: 	'Buscar',
                    opcion: this.opcion.code,
                    fecha1: Consulta.fdate(this.fecha1),
                    fecha2: Consulta.fdate(this.fecha2),
                    }).then(response => {
                        //this.$store.state.error = response.result.poliza[0];
                        if (response.result.poliza){
                            this.poliza = response.result.poliza;
                            this.polizas = response.result.poliza;
                        }
                        this.monto = response.result.monto;
                        this.pagos = response.result.pagos;
                        this.deudas = response.result.deudas;
                        this.efectivo = response.result.efectivo;
                        this.iss = response.result.iss;
                        this.broker = response.result.broker;
                        this.$store.commit('Loading');
                    });
            }
        },
        Ver(data){
            const doc = new PDF();
            doc.Certificado(data);
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},

	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>

<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reporte')+': '+$t('Ventas A')}} </h3>
		</div>
        <div class="p-col-12">
            <div class="card">
                <div class="p-grid">
                    <div class="p-col-3">
                        <SelectButton v-model="option" :options="options" :optionLabel="$t('nombre')" aria-labelledby="basic"/>
                    </div>
                    <div class="p-col-8 p-grid">
                        <div class="p-col-3" v-if="option.code>0">
                            <Dropdown v-model="tipo" :options="tipos" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                        </div>
                        <div class="p-col-2" v-if="option.code==1">
                            <Dropdown v-model="year" :options="$store.state.years" optionLabel="nombre"></Dropdown>
                        </div>
                        <div class="p-col-2" v-if="option.code==1">
                            <Dropdown v-model="mes" :options="$store.state.meses" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                        </div>
                        <div class="p-col-3" v-if="option.code==2">
                            <InputMask v-model="fecha1" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                        </div>
                        <div class="p-col-3" v-if="option.code==2">
                            <InputMask v-model="fecha2" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                        </div>
                        <div class="p-col-9" v-if="option.code==0">
                            <InputText v-model="buscar" type="number"/>
                        </div>
                    </div>
                    <div class="p-col-1">
                        <Button icon="pi pi-search" class="p-button-rounded p-button-primary p-mr-1" v-tooltip.bottom="$t('Buscar')"  @click="Buscar()"/>
                    </div>
                </div>
            </div>
        </div>
		<div class="p-col-12" v-if="tipo">
			<div class="card">
                <!-- Incendio -->
                <DataTable :value="facturas" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm"
					:paginator="true" :rows="25"  v-if="tipo.code!=2">
                    <template #header>
                        <div class="table-header">
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filtro" placeholder="Buscar..." @keyup="Filtrar"/>
                                <!--<InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />-->
                            </span>
                            <Button icon="pi pi-download" class="p-button-rounded p-button-warning p-mr-2" @click="VerXLS()" v-if="facturas.length>0"/>
                        </div>
                    </template>
                    <Column :header="$t('Fecha')" field="activacion" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fpago)}}                              
                        </template>
                    </Column>   
                    <Column field="codigo" :header="$t('Número Póliza')" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo+slotProps.data.renovacion}}                        
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Customer number')" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')+' #'" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                            
                        </template>
                    </Column>
                    <Column field="duration" :header="$t('Duración')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.duration}} 
                        </template>
                    </Column>
                    <Column field="activacion" :header="$t('Activación')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.activacion}}
                        </template>
                    </Column>
                    <Column field="vence" :header="$t('Vencimiento')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.vence}}
                        </template>
                    </Column>
                    <Column :header="$t('Recibo')" field="factura" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.factura}}                              
                        </template>
                    </Column>   
                    <Column field="cliente" :header="$t('Usuario')+' #'" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.created.nombre}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Oficina')" field="created.origen" :sortable="true" headerStyle="width: 250px">
                        <template #body="slotProps">
                            {{slotProps.data.created.origen}}                              
                        </template>
                    </Column>   
                    <Column :header="$t('Cobertura Adicional')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.monto*0)}}
                        </template>
                    </Column>
                    <Column :header="$t('Forma de pago')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.efectivo>0">
                                Cash
                            </div>
                            <div v-else>
                                No Cash
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('Cuotas')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.evaluacion.cuotas}}
                        </template>
                    </Column>
                    <Column :header="$t('Cargo Cuota')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.primabrutat*slotProps.data.evaluacion.porcentaje/100)}}
                        </template>
                    </Column>
                    <Column :header="$t('Prima WA')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.primabrutat*(1+slotProps.data.evaluacion.porcentaje/100)*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>
                    <Column :header="$t('Costo')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.kosten)}}
                        </template>
                    </Column>
                    <Column :header="$t('OB')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.ob)}}
                        </template>
                    </Column>
                    <Column :header="$t('Prima Total')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.monto)}}
                        </template>
                    </Column>
                </DataTable>

                <!-- Vehiculo -->
                <DataTable :value="facturas" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm"
					:paginator="true" :rows="25"  v-if="tipo.code==2">
                    <template #header>
                        <div class="table-header">
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filtro" placeholder="Buscar..." @keyup="Filtrar"/>
                                <!--<InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />-->
                            </span>
                            <Button icon="pi pi-download" class="p-button-rounded p-button-warning p-mr-2" @click="VerXLS()" v-if="facturas.length>0"/>
                        </div>
                    </template>
                    <Column :header="$t('Fecha')" field="activacion" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fpago)}}                              
                        </template>
                    </Column>   
                    <Column field="codigo" :header="$t('Número Póliza')" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo+slotProps.data.renovacion}}                        
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Customer number')" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Nombre Completo')+' #'" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                            
                        </template>
                    </Column>
                    <Column field="duration" :header="$t('Duración')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.duration}} 
                        </template>
                    </Column>
                    <Column field="activacion" :header="$t('Activación')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.activacion}}
                        </template>
                    </Column>
                    <Column field="vence" :header="$t('Vencimiento')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.vence}}
                        </template>
                    </Column>
                    <Column :header="$t('Recibo')" field="factura" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.factura}}                              
                        </template>
                    </Column>   
                    <Column field="cliente" :header="$t('Usuario')+' #'" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.created.nombre}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Oficina')" field="created.origen" :sortable="true" headerStyle="width: 250px">
                        <template #body="slotProps">
                            {{slotProps.data.created.origen}}                              
                        </template>
                    </Column>   
                    <Column :header="$t('Prima Bruta WA')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.primaini*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>                    
                    <Column :header="$t('Recargo Años del Vehículo')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.recarga_veh*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>
                    <Column :header="$t('Recargo Edad Persona')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.recarga_edad*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>
                    <Column :header="$t('Recargo Volante')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.recarga_volante*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>
                    <Column :header="$t('Total WA')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.totalwa*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>
                    <Column :header="$t('Sin reclamación')" headerStyle="width: 180px">sss
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.totalwa*slotProps.data.evaluacion.noclaim*slotProps.data.evaluacion.duracion)}}
                       </template>
                    </Column>
                    <Column :header="$t('Cobertura Adicional')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.monto*0)}}
                        </template>
                    </Column>
                    <Column :header="$t('Forma de pago')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.efectivo>0">
                                Cash
                            </div>
                            <div v-else>
                                No Cash
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('Descuento Especial')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.totalwa*slotProps.data.evaluacion.duracion*slotProps.data.evaluacion.valor)}}
                        </template>
                    </Column>
                    <Column :header="$t('Cuotas')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.evaluacion.cuotas}}
                        </template>
                    </Column>
                    <Column :header="$t('Cargo Cuota')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.primabrutat*slotProps.data.evaluacion.porcentaje/100)}}
                        </template>
                    </Column>
                    <Column :header="$t('Prima WA')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.primabrutat*(1+slotProps.data.evaluacion.porcentaje/100)*slotProps.data.evaluacion.duracion)}}
                        </template>
                    </Column>
                    <Column :header="$t('Costo')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.kosten)}}
                        </template>
                    </Column>
                    <Column :header="$t('OB')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.evaluacion.ob)}}
                        </template>
                    </Column>
                    <Column :header="$t('Prima Total')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.monto)}}
                        </template>
                    </Column>
                    <Column :header="$t('Sabee')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            <div v-if="(slotProps.data.sabee)<0" style="color:red;">
                                {{moneda(slotProps.data.sabee)}}
                            </div>
                            <div v-else>
                                {{moneda(slotProps.data.sabee)}}
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('Broker')" headerStyle="width: 180px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.broker<0" style="color:red;">
                                {{moneda(slotProps.data.broker)}}
                            </div>
                            <div v-else>
                                {{moneda(slotProps.data.broker)}}
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('Cambio')" headerStyle="width: 80px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.vigente">
                                <i class="pi pi-fast-backward" v-if="slotProps.data.cambio"></i>
                            </div>
                        </template>
                    </Column>                   
                </DataTable>
            </div>
        </div>
	</div>
	

</template>

<script>
import API from "../service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";
import XLS from "../service/XLS";

export default {
	components: {
    },
	data() {
		return {
			insert: false,
			edit: false,
			del: false,
			i18n: null,
            caso: null,
            facturas: [],
			selectedCustomers: null,
			filters: {},
			url: null,
			option: {code: 1, nombre: 'Mes', name: 'Month'},
			options: [
				{code: 0, nombre: 'Póliza', name: 'Policy'},
				{code: 1, nombre: 'Mes', name: 'Month'},
				{code: 2, nombre: 'Fecha', name: 'Date'},
			],
            tipo: null,
            tipos: [],
			year: null,
			mes: null,
			buscar: null,
            monto: 0,
            pagos: 0,
            deudas: 0,
            efectivo: 0,
            iss: 0,
            broker: 0,
            fecha1: null,
            fecha2: null,
            resultado: [],
            calculando: false,
		}
	},
	productService: null,
	eventService: null,
	created() {
		this.year = this.$store.state.year;
		this.mes = this.$store.state.mes;
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
			this.$store.commit('Loading');
			this.totaldeudas = 0;
            const Consulta = new API('Reportes');
            Consulta.Ini('Report14').then(result => {
                //this.$store.state.error = result;	
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
                this.tipos = result.consult.tipos;
                this.$store.commit('Loading');
            }); 
        },
        Filtrar() {
			if (this.filtro==null || this.filtro==''){
				this.facturas = this.resultado;
			} else {
				this.facturas = [];
				this.facturas = this.resultado.filter(val => 
					val.codigo.toUpperCase().includes(this.filtro.toUpperCase()) ||
                    val.cliente.toUpperCase().includes(this.filtro.toUpperCase()) ||
                    val.nombre.toUpperCase().includes(this.filtro.toUpperCase()) ||
                    val.created.nombre.toUpperCase().includes(this.filtro.toUpperCase()) ||
                    val.created.origen.toUpperCase().includes(this.filtro.toUpperCase())
				);	
			}
		},
        VerXLS(){
            const xls = new XLS();
            xls.Reporte14(this.facturas);
		},
        Buscar(){
			if((this.option.code==1 && this.tipo && this.mes && this.year)
                || (this.option.code==2 && this.tipo && this.fecha1 && this.fecha2)
                || (this.option.code==0 && this.buscar)
            ){
				this.$store.commit('Loading');
				this.facturas = [];
				const Consulta = new API('Reportes');
				Consulta.Procesar('Report14',{
					caso: 	'Buscar',
					opcion: this.option.code,
					mes: this.mes.code,
					year: this.year.code,
					tipo: this.tipo,
                    fecha1: Consulta.fdate(this.fecha1),
                    fecha2: Consulta.fdate(this.fecha2),
					poliza: this.buscar,
					}).then(response => {
						//this.$store.state.error = response;
                        this.resultado = response.result.ventas;
                        if(this.option.code==0 && response.result.ventas.length){
                            this.tipo = this.tipos.filter(val => val.code == response.result.ventas[0].tipo)[0];	
                        }
                        this.monto = response.result.monto;
                        this.pagos = response.result.pagos;
                        this.deudas = response.result.deudas;
                        this.efectivo = response.result.efectivo;
                        this.iss = response.result.iss;
                        this.broker = response.result.broker;
                        this.Filtrar();
						this.$store.commit('Loading');
					});
			}
        },
        Ver(data){
            const doc = new PDF();
            doc.Factura(data);
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
